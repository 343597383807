.qr-container, .qr-container-lp1 {
  margin-left: 150px;
  display: none;
  flex-direction: row;
}

.qr-container-lp1 {
  margin-left: 100px;
}

.qr-image-container {
  position: relative;
  top: 0;
  left: 0;
}

.blue-rectangle {
  height: 200px;
  width: 200px;
  position: relative;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
}

.qr-image {
  height: 190px;
  width: 190px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  height: 100px;
  width: 100px;
}

.scan-instruction-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.scan-text {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 300;
  font-size: 30px;
  color: white;
  margin-top: 10px;
}

.qr-line {
  margin-top: 10px;
}


@media (min-width: 800px) {
  .qr-container, .qr-container-lp1{
    display: flex;
  }
}