.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* width: 100vw; */
  background-color: #191c21;
}

.container, .container-lp1 {
  align-items: center;
  background-image: url("../assets/images/mobile-background.webp");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* justify-content: space-between; */
  padding-bottom: 8%;
  padding-top: 2%;
  /* width: 100vw; */
}

.sub-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 8%;
  align-items: center;
}

.footer-container {
  height: 28vh;
  background-image: url("../assets/images/mobile-nav-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-evenly;
  width: 100vw;
}

.download-link {
  text-decoration: none;
}

.nav-button {
  align-items: center;
  color: rgba(168, 211, 255, 1);
  display: flex;
  height: 8vh;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}

.copyright-company {
  font-weight: 700;
}

.title-and-button, .title-and-button-lp1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  /* margin-top: 350px; */
}

.title-and-button-lp1{
  gap: 5px;
}


.logos, .logos-lp1 {
  height: 17%;
  object-fit: contain;
  width: 60%;
}

.logos-lp1 {
  width: 80%;
}

.nav-link {
  color: rgba(168, 211, 255, 1);
  text-decoration: none;
}

.title, .mobile-title-lp1, .desktop-title-lp1 {
  color: white;
  line-height: 30%;
  text-align: center;
}

.mobile-title-lp1 {
  line-height: 10%;
}

.desktop-title-lp1 {
  display: none;
}

.download-button, .download-button-lp1 {
  align-items: center;
  background-color: #1467b5;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.download-icon-container {
  background-color: #1467b5;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px;
}

.download-icon {
  height: 26.03px;
  width: 25.96px;
}

.download-text {
  background-color: #187fe0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 15px;
}

.copyright, .copyright-lp1 {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 9px;
  justify-content: center;
  margin-bottom: 59px;
  text-align: center;
}

.qrCode {
  display: none;
}

.top-text, .bottom-text-lp1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 37px;
}

.bottom-text, .top-text-lp1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 45px;
}

.top-text-lp1 {
  line-height: 25px;
}

.footer-nav, .footer-nav-lp1 {
  margin-top: 50px;
}

.desktop-players, .desktop-players-lp1 {
  display: none;
}

.mobile-players {
  display: flex;
  width: 100vw;
  height: 16rem;
}

.mobile-players-lp1 {
  display: flex;
  width: 78vw;
  height: 16rem;
}

.mobile-landscape {
  display: none;
}

@media (min-width: 300px) {
  .mobile-players {
    height: 21rem;
  }

  .mobile-players-lp1 {
    height: 23rem;
  }
}

@media (max-height: 400px) and (max-width: 800px) and (orientation: landscape) {
  .mobile-landscape {
    display: flex;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: white;
    justify-content: center;
    align-items: center;
  }
  .text-style {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
  .rotate-icon-style {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    rotate: 45deg;
    animation: rotate 2s infinite ;
  }
  .main-container {
    display: none
  }

  @keyframes rotate {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
}

@media (min-width: 800px) {
  .main-container{
    display: flex;
  }
  .container, .container-lp1 {
    background-image: url("../assets/images/desktop-background.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* justify-content: space-evenly; */
    padding-bottom: 0;
    padding-top: 3rem;
    flex-direction: row;
    align-items: flex-end;
  }

  .container-lp1 {
    justify-content: space-between;
    padding: 0;
  }

  .mobile-landscape {
    display: none;
  }

  .footer-container {
    align-items: center;
    width: 100%;
    background-image: url("../assets/images/abc.png");
    flex-direction: row;
    gap: 10px;
    height: 13rem;
    justify-content: space-between;
  }

  .footer-nav, .footer-nav-lp1 {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 75px;
    margin-left: 150px;
    margin-top: 0px;
  }

  .footer-nav-lp1 {
    margin-left: 100px;
  }

  .nav-button {
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
  }

  .copyright, .copyright-lp1 {
    align-items: center;
    font-size: 16px;
    margin-bottom: 0px;
    margin-right: 150px;
  }

  .copyright-lp1 {
    margin-right: 100px;
  }

  .logos, .logos-lp1 {
    height: 180px;
    margin-left: 150px;
    width: 484px;
  }

  .logos-lp1 {
    margin-left: 100px;
  }

  .download-button {
    display: none;
  }

  .qrCode {
    display: flex;
    height: 188px;
    margin-left: 150px;
    width: 492px;
  }

  .top-text, .bottom-text-lp1 {
    font-size: 75px;
    font-weight: 700;
    line-height: 81px;
    width: 70%;
  }

  .bottom-text-lp1 {
    font-size: 84px;
    line-height: 110px;
    width: 89%;
  }

  .bottom-text, .top-text-lp1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 60px;
  }

  .top-text-lp1 {
    font-size: 31px;
    font-weight: 400;
    line-height: 45px;
  }

  .title, .desktop-title-lp1 {
    text-align: left;
  }

  .desktop-title-lp1 {
    display: flex;
    flex-direction: column;
  }

  .title-and-button, .title-and-button-lp1 {
    gap: 0px;
    margin-left: 150px;
    margin-top: 6rem;
  }

  .title-and-button-lp1 {
    margin-left: 100px;
    align-items: initial;
  }

  .border {
    border-color: white;
    border-style: solid;
    border-width: 1px;
    height: 4vh;
  }

  .qr-container, .qr-container-lp1 {
    margin-top: 6rem;
  }

  .desktop-players, .desktop-players-lp1 {
    display: flex;
    width: 60rem;
    height: 52rem;
    justify-self: flex-end;
  }

  .desktop-players-lp1 {
    width: 47rem;
    height: 55rem;
  }

  .sub-container {
    margin-bottom: 5rem;
    flex: unset;
    padding-bottom: 0px;
    align-items: unset;
  }

  .mobile-players {
    display: none;
  }
  .nav-link {
    color: rgba(255, 255, 255, 1);
  }

  .mobile-title-lp1, .mobile-players-lp1, .download-button-lp1 {
    display: none;
  }
}

@media (min-width: 1500px) {
  .container-lp1 {
    align-items: center;
    justify-content: space-evenly;
  }

  .desktop-players-lp1 {
    width: 52rem;
    height: 61rem;
  }

  .top-text-lp1 {
    font-size: 40px;
    font-weight: 400;
    line-height: 59px;
  }

  .bottom-text-lp1 {
    font-size: 105px;
    line-height: 126px;
    width: 100%;
  }

  .logos-lp1 {
    height: 219px;
    width: 550px;
  }
  
  .footer-nav-lp1 {
    margin-left: 250px;
  }

  .copyright-lp1 {
    margin-right: 250px;
  }
}