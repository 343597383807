.screen-header {
    position: relative;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%
}

.logo {
    position: absolute;
    top: 5px;
    left: 20px;
    height: 50px;
} 

@media (min-width: 600px) {
    .screen-header {
        height: 90px;
    }

    .logo {
        height: 80px;
    }
}